import Vue from 'vue';
import Vuex from 'vuex';
import { routerListA } from "../router/routerList";
import router from '../router';
import requst from '../utils/serve/request';
import ElementUI from 'element-ui';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    indexActive: [],
    routerPath: {},
    defaultActive: null,
    newRouterList: [],
    platformData: {},
    ORIGINAL_THEME: "#409EFF",
    buttonTextColor: '#333',
    styleMode: 'dark', //dark：夜间模式，light：白天模式
    projectCurrentRowData: {} // 项目管理当前行数据
  },
  mutations: {
    setDefaultActive(state, e) {
      state.defaultActive = "/" + e.path.split("/")[1];
    },
    //设置当前页面路由
    setRouterPath(state, e) {
      setTimeout(() => {
        if (state.newRouterList.length < 1) {
          return
        }
        let arr = state.newRouterList[0].children.filter((item) => {
          return item.path == e.path.split("/")[1];
        });
        if (arr.length < 1) {
          return
        }
        state.routerPath = arr[0];
        let paths = e.path.split("/");
        paths.splice(0, 1);
        if (paths.length < 2) {
          for (let i in state.routerPath.children) {
            if (state.routerPath.children[i].alias != undefined) {
              for (let j in state.routerPath.children[i].children) {
                if (state.routerPath.children[i].children[j].alias != undefined) {
                  state.indexActive = [i, j];
                }
              }
            }
          }
        } else {
          for (let i in state.routerPath.children) {
            if (state.routerPath.children[i].path == paths[1]) {
              for (let j in state.routerPath.children[i].children) {
                if (state.routerPath.children[i].children[j].path == paths[2]) {
                  state.indexActive = [i, j];
                }
              }
            }
          }
        }
      }, 100);
    },
    //清空路由
    clearNewRouter(state) {
      state.newRouterList = [];
    },
    //设置新路由表
    setNewRouter(state, e) {
      state.newRouterList = [];
      let list = e.routerArr;
      list[0].alias = '/'
      for (let i in list) {
        list[i].component = resolve => require(['@/views/' + list[i].path + '/index.vue'], resolve);
        if (list[i].children && list[i].children.length > 0) {
          list[i].children[0].alias = '';
          for (let j in list[i].children) {
            list[i].children[j].component = resolve => require(['@/views/' + list[i].path + '/' + list[i].children[j].path + '/index.vue'], resolve);
            if (list[i].children[j].children && list[i].children[j].children.length > 0) {
              list[i].children[j].children[0].alias = '';
              for (let k in list[i].children[j].children) {
                delete list[i].children[j].children[k].children;
                list[i].children[j].children[k].component = resolve => require(['@/views/' + list[i].path + '/' + list[i].children[j].path + '/' + list[i].children[j].children[k].path + '.vue'], resolve);
                if (list[i].children[j].children[k].name=="listdtu") {
                  list[i].children[j].children[k].meta['keepAlive']=true; 
                  console.log(list[i].children[j].children[k]);
                }
              }
            }
          }
        }
      }
      let arr1 = routerListA;
      arr1[0].children = list;
      router.addRoutes(arr1);
      state.newRouterList = arr1;
    },
    //设置平台方信息
    setInfoByDomain(state, e) {
      state.platformData = e;
      state.ORIGINAL_THEME = e.themeColor;
      state.buttonTextColor = e.buttonColor;
      document.title = e.platformName;
      if (typeof e.imageLogin == 'string') {
        state.platformData.imageLogin = e.imageLogin;
      }
      if (typeof e.imageLogo == 'string') {
        state.platformData.imageLogo = e.imageLogo;
      }
    },
    // 存储项目管理，当前操作数据
    setProjectCurrentRowData(state, e) {
      state.projectCurrentRowData = e || {};
      localStorage.setItem("projectCurrentRowData", JSON.stringify(e));
    },
    setStyleMode(state,e) {
      state.styleMode=e;
      localStorage.setItem("styleMode",e);
    }
  },
  actions: {
    //获取平台方信息
    getInfoByDomain({ commit }) {
      if (
        localStorage.getItem('platformData')!=undefined && 
        localStorage.getItem('platformData')!='undefined' && 
        localStorage.getItem('platformData') && 
        localStorage.getItem('platformData') != 'null'
      ) {
        let localPlatformData = JSON.parse(localStorage.getItem('platformData'))
        commit('setInfoByDomain', localPlatformData);
      }
      let domain = window.location.host.replace("www.", "");
      return requst.getInfoByDomain(domain).then((res) => {
         if (res.code != 0) {
           ElementUI.Message.error('租户信息查询失败('+res.msg+")");
           localStorage.clear();
           sessionStorage.clear();
         }
         localStorage.setItem('platformData', JSON.stringify(res.data));
         commit('setInfoByDomain', res.data);
      });
    }
  },
  modules: {
  }
})
