import { $ajax } from "../api";

// 大屏管理
export default class Screen {
  constructor() {}

  static screen = {
    info: "/api/mo-data/screen/info.action",
    weather: "/api/mo-data/screen/weather.action",
    energyStatic: '/api/mo-data//screen/energyStatic.action',
    energyFlow: '/api/mo-data/screen/energyFlow.action',
    screenFault: '/api/mo-data/screen/fault.action',
    projectStatic: '/api/mo-data/screen/projectStatic.action',
    tenantHome: '/api/mo-data/screen/tenantHome.action',
  };

  // 获取租户首页信息
  static tenantHome = (params) => {
    return $ajax.post(`${this.screen.tenantHome}`, params).then((res) => res.data);
  };

  // 查询大屏首页概要信息
  static dataScreenInfo = (params) => {
    return $ajax.post(`${this.screen.info}`, params).then((res) => res.data);
  };

  // 查询项目所在地天气
  static screenWeather= (params) => {
    return $ajax.post(`${this.screen.weather}`, params).then((res) => res.data);
  };

  // 项目大屏能耗管理(能耗统计）
  static energyStatic= (params) => {
    return $ajax.post(`${this.screen.energyStatic}`, params).then((res) => res.data);
  };

  // 项目大屏能耗管理(能流统计）
  static energyFlow= (params) => {
    return $ajax.post(`${this.screen.energyFlow}`, params).then((res) => res.data);
  };

  // 项目大屏故障管理
  static screenFault= (params) => {
    return $ajax.post(`${this.screen.screenFault}`, params).then((res) => res.data);
  };


  // 项目大屏故障管理
  static projectStatic= (params) => {
    return $ajax.post(`${this.screen.projectStatic}`, params).then((res) => res.data);
  };

}
