import { $ajax } from '../api'
import axios from 'axios';
// 公共配置模块

export default class Config {
    
    constructor() { }

    static configBath = {
        getInfoByDomain: '/api/mo-admin/tenant/getInfoByDomain', 
        qiniuUploadToken: '/api/mo-admin/qiniu/qiniuUploadToken.action',    //获取七牛云token等
        resetSelfPassword: '/api/mo-admin/tenant/resetSelfPassword.action', //修改密码
        uploadQiniu: 'https://upload.qiniup.com/',                          //上传七牛
        getProvince: '/api/mo-admin/tenant/getProvince.action',             //下属省份
        getCity: '/api/mo-admin/tenant/getCity.action',                     //下属城市
        getArea: '/api/mo-admin/tenant/getArea.action',                     //下属区县
        tenantInfo: '/api/mo-admin/tenant/tenantInfo.action',                     //获取租户信息
        tenantEdit: '/api/mo-admin/tenant/tenantEdit.action',                     //编辑租户信息
    }

    /**
     * 获取租户信息
     */
    static tenantInfo = (params) => {
        return $ajax.post(`${this.configBath.tenantInfo}`,params).then(res => res.data)
    }

    /**
     * 编辑租户信息
     */
    static tenantEdit = (params) => {
        return $ajax.post(`${this.configBath.tenantEdit}`,params).then(res => res.data)
    }

    /**
     * 获取平台方信息
     */
    static getInfoByDomain = (params) => {
        return axios.get(`${this.configBath.getInfoByDomain}?domain=${params}`).then(res => res.data)
    }

    /**
     * 获取七牛云token等
     */
    static qiniuUploadToken = () => {
        return $ajax.get(`${this.configBath.qiniuUploadToken}`).then(res => res.data)
    }

    /**
    * 修改密码
    */
    static resetSelfPassword = (params) => {
        return $ajax.post(`${this.configBath.resetSelfPassword}`, params).then(res => res.data)
    }

    /**
     * 上传七牛
     */
    static uploadQiniu = (params) => {
        return $ajax.post(`${this.configBath.uploadQiniu}`, params).then((res) => res.data)
    }

    //下属省份
    static getProvince = () => {
        return $ajax.post(`${this.configBath.getProvince}`).then(res => res.data);
    }

    //下属城市
    static getCity = (params) => {
        return $ajax.post(`${this.configBath.getCity}`, params).then(res => res.data);
    }

    //下属区县
    static getArea = (params) => {
        return $ajax.post(`${this.configBath.getArea}`, params).then(res => res.data);
    }

}