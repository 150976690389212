<template>
  <el-container>
    <el-header :style="{background:bgColor}">
      <div class="header-left">
        <div class="header-logo">
            <img style="height:24px;width:24px;margin-left:15px;" :src="styleMode=='dark'?menuDark:menuLight"/>
        </div>
        <div class="header-title">
          <div class="header-title" :style="{color:txtColor}">{{ platformData.platformName }}</div>
        </div>
      </div>
      <div class="header-menu">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-demo"
          mode="horizontal"
          :style="{background:bgColor}"
          router>
          <template v-for="(item, index) in list">
            <el-menu-item v-if="!item.hidden" :key="index" :index="'/' + item.path" class="menu-text" :style="{color:txtColor}">{{ item.meta.title }}</el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="header-right">
        <div class="project-operation-div">
          <div class="operation-search">
            <el-input size="mini" class="custom-input" placeholder="搜索"  @keyup.enter.native="onSearchAll" @blur="onSearchAll"  @clear="onClear" clearable>
              <i slot="prefix" class="el-input__icon el-icon-search"/>
            </el-input>
          </div>
        </div>
        <img style="width:23px;height:23px;margin-right:10px;" :src="styleMode=='dark'?styleLight:styleDark" @click="switchMode"/>
        <img style="width:23px;height:23px;margin-right:10px;" :src="styleMode=='dark'?alertWhite:alertBlack" @click="showAlert"/>
        <img style="width:23px;height:23px;" :src="styleMode=='dark'?helpWhite:helpBlack" @click="showHelp"/>
        <el-dropdown @command="userOperation">
          <span class="el-dropdown-link">
            <span class="name" :style="{color:txtColor}">{{ userInfo.name }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>

    <el-main style="padding:0px;">
      <router-view></router-view>
    </el-main>

    <!-- 修改密码弹窗 -->
    <ChangePassword @dialogClose="dialogClose" :dialogVisible="dialogVisible" />

  </el-container>
</template>

<script type="text/javascript">
import ChangePassword from "./login/module/changePassword.vue";
import { mapState } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      list: [],
      dialogVisible: false,
      userInfo: {},
      styleMode: "dark",
      bgColor: "#1F285A",
      txtColor: "white",
      alertWhite: require("@/assets/images/alert-white.png"),
      alertBlack: require("@/assets/images/alert-black.png"),
      helpWhite: require("@/assets/images/help-white.png"),
      helpBlack: require("@/assets/images/help-black.png"),
      styleLight: require("@/assets/images/style-light.png"),
      styleDark: require("@/assets/images/style-dark.png"),
      menuDark: require("@/assets/images/menu-dark.png"),
      menuLight: require("@/assets/images/menu-light.png"),
    };
  },
  mounted() {
    this.setMenuLang();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.styleMode=localStorage.getItem("styleMode");
    if (this.styleMode=='dark') {
      this.styleMode="light";
      this.switchMode();
    } else if (this.styleMode=='light') {
      this.styleMode="dark";
      this.switchMode();
    } else {
      this.styleMode="light";
      this.switchMode();
    }
  },
  components: {
    ChangePassword
  },
  computed: {
    ...mapState(["defaultActive","newRouterList","platformData"]),
  },
  methods: {
    onSearchAll() {
      
    },
    onClear() {
      
    },
    switchMode() {
      if (this.styleMode=='dark') {
        this.styleMode='light';
        this.bgColor="white";
        this.txtColor="black";
      } else {
        this.styleMode='dark';
        this.bgColor="#1F285A";
        this.txtColor="white";
      }
      store.commit("setStyleMode",this.styleMode);
      this.$forceUpdate();
    },
    showAlert() {
      this.$message({
        type: "success",
        message: "建设中...",
      });
    },
    showHelp() {
      this.$message({
        type: "success",
        message: "建设中...",
      });
    },
    setMenuLang() {
      this.list = this.newRouterList[0].children;
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    userOperation(e) {
      if (e == 2) {
        this.$confirm("你确定要退出吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.request.logout().then(() => {
            this.$message({
              type: "success",
              message: "退出成功!",
            });
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {});
      }
      if (e == 1) {
        this.dialogVisible = true;
      }
    },
  }
}
</script>


<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.el-container {
  overflow: auto;
}
/*
.el-header {
  color: #000;
  display: flex;
  padding: 0 10px;
  align-items: center;
  margin-bottom: 1px;
  z-index: 1;
  height: 60px;
  box-shadow: 0px 3px 6px rgba(210, 222, 244, 0.69);
}
*/  
.el-header {
  color: #000;
  display: flex;
  padding: 0 10px;
  align-items: center;
  z-index: 1;
  height: 60px;
}
.menu-text {
  font-size:14px;
}
.header-left {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.header-title {
  font-size: 16px;
  margin: 0 15px;
  font-family: "microsoft yahei ui","microsoft yahei";
}
.el-header .header-menu {
  display: flex;
  align-items: center;
}
.el-header .header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  position: fixed;
  right: 10px;
}
.el-header .header-right .el-dropdown-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.el-header .header-right .el-dropdown-link .name {
  margin-left: 7px;
  font-size: 14px;
  height: 23px;
  line-height: 23px;
  color: #333000;
}
.view {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
}
.layout-row {
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.layout-col {
  height: 100%;
  overflow: hidden;
}
.layout-right {
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-region {
  background: rgba(210, 222, 244, 0.35);
  border-radius: 15px;
  padding: 4px 8px;
  box-sizing: border-box;
}
.el-menu-item {
  border-bottom: none !important;
}
.el-menu-item.is-active {
  font-weight:bold !important;
  background: #1f5bcc !important;
  color: white !important;
  border-bottom: none !important;
}
.el-menu-item:hover {
  background-color: #1f5bcc !important;
  color: white !important;
}
.project-operation-div {
  display: flex;
  align-items: center;
  .el-button {
    margin: 0;
    background-color: #326fff;
  }
  .operation-search {
    width: 150px;
    margin-right: 20px;
  }
}
.custom-input {
  border: 0.5px solid #333000;
  border-radius: 5px;
}
</style>