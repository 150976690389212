import { $ajax } from '../api'

// 系统模块

export default class Ststem {
    constructor() { }

    static systemBath = {
        //角色模块
        listAllMenuButtons: '/api/mo-admin/tenant/role/listAllMenuButtons.action', //查询租户下菜单及按钮
        roleManagementList: '/api/mo-admin/tenant/role/list.action', //角色管理-列表（分页）
        roleManagementListAll: '/api/mo-admin/tenant/role/listAll.action',//角色管理-查询所有角色   
        roleManagementAdd: '/api/mo-admin/tenant/role/add.action', //新增角色
        roleManagementDelete: '/api/mo-admin/tenant/role/delete.action', //角色管理删除角色
        roleManagementInfo: '/api/mo-admin/tenant/role/info.action', //角色管理-根据id查询菜单按钮权限
        roleManagementEdit: '/api/mo-admin/tenant/role/edit.action', //角色管理-编辑
        //部门模块
        listOrg: '/api/mo-admin/tenant/organization/listAll.action', //部门管理-查询所有部门
        deleteOrg: '/api/mo-admin/tenant/organization/delete.action',// 部门管理-删除部门
        addOrg: '/api/mo-admin/tenant/organization/add.action', //部门管理-新增部门
        editOrg: '/api/mo-admin/tenant/organization/edit.action', //部门管理-编辑部门
        //账号模块
        accountManagementList: '/api/mo-admin/tenant/account/list.action', // 账号管理-列表（分页）
        accountManagementAdd: '/api/mo-admin/tenant/account/add.action', //账号管理-新增
        accountManagementEdit: '/api/mo-admin/tenant/account/edit.action', //账号管理-编辑
        accountManagementDelete: '/api/mo-admin/tenant/account/delete.action', // 账号管理-删除
        accountManagementResetPassword: '/api/mo-admin/tenant/account/resetPassword.action',// 账号管理-重置密码
        accountManagementShare: '/api/mo-admin/tenant/account/share.action', //账号管理-一键分享
        accountManagementInfo: '/api/mo-admin/tenant/account/info.action', // 账号管理，根据id查详情
        listChildAccounts: '/api/mo-admin/tenant/account/listAll.action', // 账号管理-所有用户（分页）
        //日志管理
        apiList: '/api/mo-admin/system/operationLog/apiList.action', //日志维护列表
        apiAdd: '/api/mo-admin/system/operationLog/apiAdd.action', //日志添加
        apiEdit: '/api/mo-admin/system/operationLog/apiEdit.action', //日志编辑
        apiDelete: '/api/mo-admin/system/operationLog/apiDelete.action', //日志删除
        admList: '/api/mo-admin/system/operationLog/admList.action', //管理后台操作日志记录
        toolList: '/api/mo-admin/system/operationLog/toolList.action', //运维端日志记录
        appList: '/api/mo-admin/system/operationLog/appList.action', //APP用户操作日志记录
        queryAppRequestUrl: '/api/mo-admin/system/operationLog/queryAppRequestUrl.action', //根据条件查询app接口
    }

    //菜单模块

    /**
     * 查询所有菜单带按钮
     */
    static listWithButtons = (params) => {
        return $ajax.post(`${this.systemBath.listWithButtons}`,params).then(res => res.data)
    }

    /**
     * 查询所有菜单带按钮
     */
    static listAllMenuButtons = () => {
        return $ajax.get(`${this.systemBath.listAllMenuButtons}`).then(res => res.data)
    }

    /**
    * 新增菜单
    */
    static menuManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementAdd}`, params).then(res => res.data)
    }

    /**
     * 菜单管理删除菜单
     */
    static menuManagementDelete = (params) => {
        return $ajax.delete(`${this.systemBath.menuManagementDelete}${params}`).then(res => res.data)
    }


    /**
     * 编辑菜单
     */
    static menuManagementEdit = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementEdit}`, params).then(res => res.data)
    }

    /**
     * 新增按钮
     */
    static menuManagementAddButton = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementAddButton}`, params).then(res => res.data)
    }


    /**
     * 编辑按钮
     */
    static menuManagementEditButton = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementEditButton}`, params).then(res => res.data)
    }

    /**
     * 删除按钮
     */
    static menuManagementDeleteButton = (params) => {
        return $ajax.delete(`${this.systemBath.menuManagementDeleteButton}${params}`).then(res => res.data)
    }




    //角色模块

    /**
     * 角色管理-列表（分页）
     */
    static roleManagementList = (params) => {
        return $ajax.post(`${this.systemBath.roleManagementList}`, params).then(res => res.data)
    }


    /**
     * 角色管理-查询所有角色
     */
    static roleManagementListAll = () => {
        return $ajax.get(`${this.systemBath.roleManagementListAll}`).then(res => res.data)
    }


    /**
     * 新增角色
     */
    static roleManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.roleManagementAdd}`, params).then(res => res.data)
    }


    /**
     * 角色管理删除角色
     */
    static roleManagementDelete = (params) => {
        return $ajax.delete(`${this.systemBath.roleManagementDelete}${params}`).then(res => res.data)
    }

    /**
     * 角色管理-根据id查询菜单按钮权限
     */
    static roleManagementInfo = (params) => {
        return $ajax.get(`${this.systemBath.roleManagementInfo}${params}`).then(res => res.data)
    }

    /**
     * 角色管理-编辑
     */
    static roleManagementEdit = (params) => {
        return $ajax.post(`${this.systemBath.roleManagementEdit}`, params).then(res => res.data)
    }




    //部门模块

    /**
     * 部门管理-查询所有部门
     */
    static listOrg = () => {
        return $ajax.get(`${this.systemBath.listOrg}`).then(res => res.data)
    }

    /**
     * 部门管理-删除部门
     */
    static deleteOrg = (params) => {
        return $ajax.post(`${this.systemBath.deleteOrg}`, params).then(res => res.data)
    }

    /**
     * 部门管理-新增部门
     */
    static addOrg = (params) => {
        return $ajax.post(`${this.systemBath.addOrg}`, params).then(res => res.data)
    }

    /**
     * 部门管理-编辑部门
     */
    static editOrg = (params) => {
        return $ajax.post(`${this.systemBath.editOrg}`, params).then(res => res.data)
    }



    //账号模块

    /**
     * 账号管理-列表（分页）
     */
    static accountManagementList = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementList}`, params).then(res => res.data)
    }


    /**
     * 账号管理-新增
     */
    static accountManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementAdd}`, params).then(res => res.data)
    }

    /**
     * 账号管理-编辑
     */
    static accountManagementEdit = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementEdit}`,params).then(res => res.data)
    }


    /**
     * 账号管理-删除
     */
    static accountManagementDelete = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementDelete}`,params).then(res => res.data)
    }


    /**
     * 账号管理-重置密码
     */
    static accountManagementResetPassword = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementResetPassword}`, params).then(res => res.data)
    }


    /**
     * 账号管理-一键分享
     */
    static accountManagementShare = (params) => {
        return $ajax.get(`${this.systemBath.accountManagementShare}?id=${params}`).then(res => res.data)
    }

    /**
     * 账号管理，根据id查详情
     */
    static accountManagementInfo = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementInfo}`, params).then(res => res.data)
    }

    /**
     * 账号管理-列表（分页）
     */
    static listChildAccounts = (params) => {
        return $ajax.post(`${this.systemBath.listChildAccounts}`, params).then(res => res.data)
    }

    // 日志
    /**
     * 管理后台操作日志记录
     */
    static admList = (params) => {
        return $ajax.post(`${this.systemBath.admList}`, params).then(res => res.data)
    }
    /**
     * 运维端操作日志记录
     */
    static toolList = (params) => {
        return $ajax.post(`${this.systemBath.toolList}`, params).then(res => res.data)
    }

    // 日志
    /**
     * App操作日志记录
     */
    static appList = (params) => {
        return $ajax.post(`${this.systemBath.appList}`, params).then(res => res.data)
    }

    /**
     * 日志维护列表
     */
    static apiList = (params) => {
        return $ajax.post(`${this.systemBath.apiList}`, params).then(res => res.data)
    }

    /**
     * 管理后台操作日志记录
     */
    static admList = (params) => {
        return $ajax.post(`${this.systemBath.admList}`, params).then(res => res.data)
    }

    /**
     * 日志添加
     */
    static apiAdd = (params) => {
        return $ajax.post(`${this.systemBath.apiAdd}`, params).then(res => res.data)
    }

    /**
     * 日志编辑
     */
    static apiEdit = (params) => {
        return $ajax.post(`${this.systemBath.apiEdit}`, params).then(res => res.data)
    }

    /**
     * 日志删除
     */
    static apiDelete = (params) => {
        return $ajax.post(`${this.systemBath.apiDelete}`, params).then(res => res.data)
    }
    
    /**
    * 快捷搜索-根据条件查询app接口
    */
    static queryAppRequestUrl = (params) => {
        return $ajax.get(`${this.systemBath.queryAppRequestUrl}${params}`).then(res => res.data)
    }
}