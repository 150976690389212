import Config from './module/config'
import Login from './module/login'
import System from './module/system'
import Home from './module/home'
import Project from './module/project'
import Screen from './module/screen'
import Device from './module/device'
import Serve from './module/serve'
import Data from './module/data'

const mixinClass = (base, mixins) => {
    const mixinProps = (target, source) => {
        /* 继承 */
        Object.keys(source).forEach(keys => {
            if (keys in target) {
                console.log(typeof target[keys]);
            }
            Object.defineProperty(target, keys, Object.getOwnPropertyDescriptor(source, keys));
        });
    };
    let Ctor;
    if (base && typeof base === 'function') {
        Ctor = class extends base {
            constructor(...props) {
                super(...props);
            }
        };
        mixins.forEach(source => {
            mixinProps(Ctor, source);
        });
    } else {
        Ctor = class { };
    }
    return Ctor;
};

class OldRequest { }

let Request = mixinClass(OldRequest, [Config, Login, System, Home, Project, Screen, Device, Serve, Data]);

export default Request