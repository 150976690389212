<template>
  <div id="app" style="height:100%;">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { updateThemeColor } from "./utils/util/theme";
import store from "@/store";

export default {
  data() {
    return {};
  },
  created() {
    this.getInfoByDomain().then(() => {
      setTimeout(() => {
        updateThemeColor(this.ORIGINAL_THEME);
      }, 200);
    });
  },
  mounted() {
    this.resetStoreCurrentData();
  },
  computed: {
    ...mapState(["ORIGINAL_THEME"]),
    ...mapState(["projectCurrentRowData"]),
  },
  methods: {
    ...mapActions(["getInfoByDomain"]),
    ...mapMutations(["setTheme"]),
    // 刷新页面，重置 store 中存储的当前操作项目信息
    resetStoreCurrentData() {

      if (localStorage.getItem("projectCurrentRowData")) {
        store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            {
              ...this.$store.state,
              projectCurrentRowData: JSON.parse(localStorage.getItem("projectCurrentRowData"))
            }
          )
        );
        sessionStorage.removeItem("store");
      }

      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("projectCurrentRowData", JSON.stringify(store.state.projectCurrentRowData));
      });
    },
  },
};
</script>

<style>
@import "./assets/css/reset.css";
html,

body {
  width: 100%;
  height: 100%;
}

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;
  --white: #ffffff;
  --greyLight-1: #e4ebf5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;
}

#app {
  font-family: "microsoft yahei ui","microsoft yahei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f6f9;
  overflow: hidden;
}

.app-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.from {
  float: left;
  display: flex;
  flex-wrap: wrap;
}

.from .el-form-item {
  margin-right: 20px;
}

.search {
  margin-bottom: 20px;
}
</style>
