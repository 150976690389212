import { $ajax } from '../api'

export default class Serve {

    constructor() {}

    static serve = {
      addRule: '/api/mo-serve/serve/rule/add.action',
      listRule: '/api/mo-serve/serve/rule/list.action',
      deleteRule: '/api/mo-serve/serve/rule/delete.action',
      infoRule: '/api/mo-serve/serve/rule/info.action',
      editRule: '/api/mo-serve/serve/rule/edit.action',
      addFirmwareDtu: '/api/mo-serve/upgrade/dtu/add.action',
      editFirmwareDtu: '/api/mo-serve/upgrade/dtu/edit.action',
      firmwareDtuStatus: '/api/mo-serve/upgrade/dtu/status.action',
      firmwareDtuList: '/api/mo-serve/upgrade/dtu/list.action',
      addTask: '/api/mo-serve/upgrade/task/add.action',
      editTask: '/api/mo-serve/upgrade/task/edit.action',
      taskPublish: '/api/mo-serve/upgrade/task/publish.action',
      dtuTaskList: '/api/mo-serve/upgrade/task/dtuList.action',
      taskDelete: '/api/mo-serve/upgrade/task/delete.action',
      taskRelease: '/api/mo-serve/upgrade/task/release.action',
      taskDtuInfo: '/api/mo-serve/upgrade/task/dtuInfo.action',
      taskDtuDetail: '/api/mo-serve/upgrade/task/dtuDetail.action',
      searchDtuList: '/api/mo-serve/upgrade/dtu/search.action', 
      addOta: '/api/mo-serve/upgrade/addOta.action', 
      removeOta: '/api/mo-serve/upgrade/removeOta.action', 
      firmwareDeviceList: '/api/mo-serve/upgrade/device/list.action',
      addFirmwareDevice: '/api/mo-serve/upgrade/device/add.action',
      editFirmwareDevice: '/api/mo-serve/upgrade/device/edit.action',
      deviceTaskList: '/api/mo-serve/upgrade/task/deviceList.action',
      taskDeviceDetail: '/api/mo-serve/upgrade/task/deviceDetail.action',
      taskDeviceInfo: '/api/mo-serve/upgrade/task/deviceInfo.action',
      searchDeviceList: '/api/mo-serve/upgrade/device/search.action', 
      deviceFaults: '/api/mo-serve/fault/deviceFaults.action', 
      staticFaults: '/api/mo-serve/fault/static.action', 
      
    }


    //故障统计信息
    static staticFaults = (params) => {
      return $ajax.post(`${this.serve.staticFaults}`, params).then(res => res.data)
    }

    //搜索设备故障列表
    static deviceFaults = (params) => {
      return $ajax.post(`${this.serve.deviceFaults}`, params).then(res => res.data)
    }
  
    //添加升级设备
    static addUpgradeDevice = (params) => {
      return $ajax.post(`${this.serve.addUpgradeDevice}`, params).then(res => res.data)
    }

    //搜索设备列表
    static searchDeviceList = (params) => {
      return $ajax.post(`${this.serve.searchDeviceList}`, params).then(res => res.data)
    }


    // 获取灰度测试任务（Device）
    static taskDeviceInfo = (params) => {
      return $ajax.post(`${this.serve.taskDeviceInfo}`, params).then(res => res.data)
    }

    // 获取升级任务详情（Device）
    static taskDeviceDetail = (params) => {
      return $ajax.post(`${this.serve.taskDeviceDetail}`, params).then(res => res.data)
    }

    // 获取升级任务列表（Device）
    static deviceTaskList = (params) => {
      return $ajax.post(`${this.serve.deviceTaskList}`, params).then(res => res.data)
    }

    // 修改设备固件
    static editFirmwareDevice = (params) => {
      return $ajax.post(`${this.serve.editFirmwareDevice}`, params).then(res => res.data)
    }

    // 新增设备固件
    static addFirmwareDevice = (params) => {
      return $ajax.post(`${this.serve.addFirmwareDevice}`, params).then(res => res.data)
    }
    
    //获取设备固件列表
    static firmwareDeviceList = (params) => {
      return $ajax.post(`${this.serve.firmwareDeviceList}`, params).then(res => res.data)
    }

    //移除升级设备
    static removeOta = (params) => {
      return $ajax.post(`${this.serve.removeOta}`, params).then(res => res.data)
    }

    //添加升级设备
    static addOta = (params) => {
      return $ajax.post(`${this.serve.addOta}`, params).then(res => res.data)
    }

    //搜索网关列表
    static searchDtuList = (params) => {
      return $ajax.post(`${this.serve.searchDtuList}`, params).then(res => res.data)
    }

    // 获取升级任务详情（Dtu）
    static taskDtuDetail = (params) => {
      return $ajax.post(`${this.serve.taskDtuDetail}`, params).then(res => res.data)
    }

    // 获取灰度测试任务
    static taskDtuInfo = (params) => {
      return $ajax.post(`${this.serve.taskDtuInfo}`, params).then(res => res.data)
    }
     

    // 发布升级任务
    static taskRelease = (params) => {
      return $ajax.post(`${this.serve.taskRelease}`, params).then(res => res.data)
    }
     
    // 删除升级任务
    static taskDelete = (params) => {
      return $ajax.post(`${this.serve.taskDelete}`, params).then(res => res.data)
    }

    // 获取升级任务列表（DTU)
    static dtuTaskList = (params) => {
      return $ajax.post(`${this.serve.dtuTaskList}`, params).then(res => res.data)
    }
    
    // 发布升级任务
    static taskPublish = (params) => {
      return $ajax.post(`${this.serve.taskPublish}`, params).then(res => res.data)
    }


    // 修改升级任务
    static editTask = (params) => {
      return $ajax.post(`${this.serve.editTask}`, params).then(res => res.data)
    }

    // 新增升级任务
    static addTask = (params) => {
      return $ajax.post(`${this.serve.addTask}`, params).then(res => res.data)
    }

    // 获取DTU固件列表
    static firmwareDtuList = (params) => {
      return $ajax.post(`${this.serve.firmwareDtuList}`, params).then(res => res.data)
    }

    // 更新DTU固件状态
    static firmwareDtuStatus = (params) => {
      return $ajax.post(`${this.serve.firmwareDtuStatus}`, params).then(res => res.data)
    }

    // 修改DTU固件
    static editFirmwareDtu = (params) => {
      return $ajax.post(`${this.serve.editFirmwareDtu}`, params).then(res => res.data)
    }

    // 新增DTU固件
    static addFirmwareDtu = (params) => {
      return $ajax.post(`${this.serve.addFirmwareDtu}`, params).then(res => res.data)
    }
    
    // 新增
    static addRule = (params) => {
      return $ajax.post(`${this.serve.addRule}`, params).then(res => res.data)
    }

    // 列表
    static listRule = (params) => {
      return $ajax.post(`${this.serve.listRule}`, params).then(res => res.data)
    }

    // 删除
    static deleteRule = (params) => {
      return $ajax.post(`${this.serve.deleteRule}`, params).then(res => res.data)
    }

    // 详情
    static infoRule = (params) => {
      return $ajax.post(`${this.serve.infoRule}`, params).then(res => res.data)
    }

    // 修改
    static editRule = (params) => {
      return $ajax.post(`${this.serve.editRule}`, params).then(res => res.data)
    }
}