import { loginAxios, $ajax } from '../api'

// 登录模块

export default class Login {
    constructor() { }

    static loginBath = {
        randomImage: '/api/mo-admin/tenant/randomImage', //获取验证码
        login: '/api/mo-admin/tenant/login', // 用户登录
        logout: '/api/mo-admin/tenant/logout.action', // 用户登出
        getVerificationCode: '/api/mo-admin/tenant/getVerificationCode', //忘记密码获取验证码
        checkVerificationCode: '/api/mo-admin/tenant/checkVerificationCode', // 忘记密码-验证验证码
        resetSysUserPasswordByMail: '/api/mo-admin/tenant/resetSysUserPasswordByMail', //忘记密码-根据邮箱地址重设密码
    }

    /**
     * 获取验证码
     */
    static randomImage = (params) => {
        return loginAxios.post(`${this.loginBath.randomImage}`, params).then(res => res.data)
    }

    /**
     * 登录
     */
    static login = (params) => {
        return loginAxios.post(`${this.loginBath.login}`, params).then(res => res.data)
    }

    /**
     * 登出
     */
    static logout = (params) => {
        return $ajax.post(`${this.loginBath.logout}`, params).then(res => res.data)
    }

    /**
 * 忘记密码获取验证码
 */
    static getVerificationCode = (params) => {
        return loginAxios.post(`${this.loginBath.getVerificationCode}`,params).then(res => res.data)
    }


    /**
     * 忘记密码-验证验证码
     */
    static checkVerificationCode = (params) => {
        return loginAxios.get(`${this.loginBath.checkVerificationCode}${params}`).then(res => res.data)
    }

    /**
     * 忘记密码-根据邮箱地址重设密码
     */
    static resetSysUserPasswordByMail = (params) => {
        return loginAxios.post(`${this.loginBath.resetSysUserPasswordByMail}`, params).then(res => res.data)
    }

}