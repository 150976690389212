import { $ajax } from '../api'
import { $dxios } from '../api'

// 首页概览

export default class Home {

    constructor() { }

    static home = {
       
    }
    
    
}