import { $ajax } from '../api'

// 数据页面
export default class Data {

    constructor() {}

    static data = {
      readHoursParam: '/api/mo-data/data/readHoursParam.action',     //按小时查询
      readDateParams: '/api/mo-data/data/readDateParam.action',      //按天查询
      readDataLast: '/api/mo-data/data/readDataLast.action',         //读取设备最新数据
      deviceEvents: '/api/mo-data/data/eventList.action',            //操作事件列表
      
    }
    
    // 操作事件列表
    static deviceEvents = (params) => {
      return $ajax.post(`${this.data.deviceEvents}`, params).then(res => res.data)
    }

    // 读取设备历史数据 按小时
    static readHoursParam = (params) => {
      return $ajax.post(`${this.data.readHoursParam}`, params).then(res => res.data)
    }

    // 读取设备历史数据 - 按天
    static readDateParams = (params) => {
      return $ajax.post(`${this.data.readDateParams}`, params).then(res => res.data)
    }

    // 读取设备最新数据
    static readDataLast = (params) => {
      return $ajax.post(`${this.data.readDataLast}`, params).then(res => res.data)
    }


}