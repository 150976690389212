import Layout from '@/views/index.vue'

export const routerListA = [
    //layout
    {
        path: '/',
        component: Layout,
        hidden: false,
        meta: {
            title: 'Layout'
        },
        name: 'Layout',
        children: [
           
        ]
    },
    //登录
    {
        path: '/login',
        name: 'Login',
        component: () => import("@/views/login/index.vue"),
        meta: {
            title: '登录'
        },
        hidden: true
    },
    //忘记密码
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import("@/views/login/forgetPassword.vue"),
        meta: {
            title: '忘记密码'
        },
        hidden: true
    },
    //重置密码
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import("@/views/login/resetPassword.vue"),
        meta: {
            title: '重置密码'
        },
        hidden: true
    },
    // 可视化
    {
      path: '/visual',
      name: 'visual',
      component: () => import("@/views/screen/visual/visual.vue"),
      meta: {
          title: '可视化'
      },
      hidden: true,
    },
    {
      path: '/visual-yanshi',
      name: 'visual',
      component: () => import("@/views/screen/visual/visual-yanshi.vue"),
      meta: {
          title: '可视化'
      },
      hidden: true,
    },
    {
      path: '/device',
      name: 'device',
      component: () => import("@/views/screen/visual/device/index.vue"),
      meta: {
          title: '设备管理'
      },
      hidden: true,
      children: [
        {
          path: "",
          name: 'device',
          component: () => import("@/views/screen/visual/device/device.vue"),
          meta: {
              title: '远程控制'
          },
          hidden: true,
        },
        {
          path: "datalog",
          name: 'device',
          component: () => import("@/views/screen/visual/device/datalog.vue"),
          meta: {
              title: '数据记录'
          },
          hidden: true,
        }
      ]
    },
    {
      path: '/energy',
      name: 'energy',
      component: () => import("@/views/screen/visual/energy/index.vue"),
      meta: {
          title: '能耗管理'
      },
      hidden: true,
    },
    {
      path: '/break',
      name: 'break',
      component: () => import("@/views/screen/visual/break/index.vue"),
      meta: {
          title: '故障管理'
      },
      hidden: true,
    },
    //测试用
    {
      path: '/testSvg',
      name: 'testSvg',
      component: () => import("@/views/screen/visual/components/2dpage/testSvg.vue"),
      meta: {
          title: '404'
      },
      hidden: true
    },
    //未找到
    {
        path: '/404',
        name: '404',
        component: () => import("@/views/404.vue"),
        meta: {
            title: '404'
        },
        hidden: true
    },
    //宁夏烘干车间
    {
        path: '/nxhg',
        component: () => import("@/views/screen/nxhg/index.vue"),
        meta: {
            title: '主画面'
        },
        hidden: true,
    },
    {
        path: '/yunxing',
        component: () => import("@/views/screen/nxhg/yunxing.vue"),
        meta: {
            title: '运行'
        },
        hidden: true,
    },
    {
        path: '/guzhang',
        component: () => import("@/views/screen/nxhg/guzhang.vue"),
        meta: {
            title: '故障'
        },
        hidden: true,
    },
    {
        path: '/shuju',
        component: () => import("@/views/screen/nxhg/shuju.vue"),
        meta: {
            title: '数据'
        },
        hidden: true,
    },
]
