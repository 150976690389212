<template>
  <!-- 修改密码 -->
  <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="change-password">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        class="demo-ruleForm"
      >
        <!-- 原密码 -->
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="ruleForm.oldPassword" placeholder="请输入原密码" show-password/>
        </el-form-item>
        <!-- 新密码 -->
        <el-form-item label="确认新密码" prop="newPassword">
          <el-input v-model="ruleForm.retryPassword" placeholder="请输入新密码" show-password/>
        </el-form-item>  
        <!-- 确定新密码 -->
        <el-form-item label="新密码" prop="retryPassword">
          <el-input v-model="ruleForm.retryPassword" placeholder="再次输入密码" show-password/>
        </el-form-item>
        <el-form-item>
          <div class="button">
            <span slot="footer" class="dialog-footer">
              <el-button class="cancel" @click="handleClose()">取消</el-button>
              <el-button
                class="submit" :disabled="
                  this.ruleForm.oldPassword == '' ||
                  this.ruleForm.retryPassword == '' ||
                  this.ruleForm.newPassword == ''
                "
                type="primary"
                @click="submit()">确定</el-button>
            </span>
          </div>
          <!-- 确认修改 -->
          <div class="cancelBtn"></div>
          <!-- 取消修改 -->
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { oldPsw, newPsw, confirmPsw } from "../../../utils/util/test";
export default {
  data() {
    return {
      ruleForm: {
        oldPassword: "", //原密码
        retryPassword: "", //新密码
        newPassword: "", //确认新密码
      },
      //验证
      rules: {
        oldPassword: [{ validator: oldPsw, trigger: "blur" }],
        retryPassword: [{ validator: newPsw, trigger: "blur" }],
        newPassword: [{ validator: confirmPsw, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["dialogVisible"],
  methods: {
    //确认修改
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let str = {
            oldPassword: this.ruleForm.oldPassword,
            newPassword: this.ruleForm.newPassword,
          };
          this.request
            .resetSelfPassword(str)
            .then(() => {
              this.$message.success("修改密码成功,请重新登录!");
              setTimeout(() => {
                this.$router.push({ path: "/login" });
              }, 1000);
            })
            .catch(() => {});
        }
      });
    },
    //取消修改
    handleClose() {
      this.ruleForm = {
        oldPassword: "", //原密码
        retryPassword: "", //新密码
        newPassword: "", //确认新密码
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("dialogClose");
    },
  },
};
</script>

<style scoped>
.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.demo-ruleForm {
  width: 577px;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.submit {
  width: 110px;
  height: 40px;
  opacity: 1;
  border-radius: 3px;
}
.cancel {
  width: 110px;
  height: 40px;
  border: 1px solid #b4bdd4;
  opacity: 1;
  border-radius: 3px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>